import React from "react";

const skills = (object) => {
    return (
        <article className="skills">
            <h3>Ferdigheter og Verktøy</h3>
            <p className="skillsList">
                {object.map((object,i) => <span className="skill" key={i}>{object}</span> )}
            </p>
        </article>
    )
}

export default skills