import React from "react";

const isOnline = (object) => {
    if (object.has_online === true) {
        return (
            <article className="isOnline">
                <h3>Se CV på nett</h3>
                <ul>
                    <li>
                        <h4>{object.text}</h4>
                        <small>
                            <i>{object.url}</i>
                        </small>
                    </li>
                </ul>
            </article>
        )
    }
}

export default isOnline