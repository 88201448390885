import React from "react";
import Moment from "moment";
import 'moment/locale/nb';
import signature from "../assets/Main2.png";
import {SRLWrapper} from "simple-react-lightbox";

const coverLetter = (letter) => {
    const SRLoptions= {
        settings:{
            disableKeyboardControls:true,
            disableWheelControls:true,
            hideControlsAfter:false
        },
        buttons:{
            showAutoplayButton:false,
            showDownloadButton:false,
            showNextButton:false,
            showPrevButton:false,
            showThumbnailsButton:false,
            iconPadding:"10px",
            size:"60px"

        },
        thumbnails: {showThumbnails:false}
    }
    
    const dateformat = (x) => {
        Moment().locale('nb');
        return (
            Moment(x).format('ll')
        )
    }

    function letter_to(e,v, c=null) {
        if (e !== '') {
            if (v === 'to') {
                return (
                    <div>
                        <h4>{c}</h4>
                        <span>{e}</span>
                    </div>
                );
            }
            else if (v === 'time') {return (<time className="eduDate" dateTime="{e}">{dateformat(e)}</time>)}
            else {return null}
        } else if (c !== null) {return (<h2>{c}</h2>)}
        else {return null}
    }

    return (
        <div className="coverLetter">
            <div className="Head">
                <div className="time">

                    {(typeof letter.to != "undefined") ? (letter_to(letter.to, 'to', letter.title)) : (
                        <h3>{letter.title}</h3>
                    )}
                    {(typeof letter.date != "undefined") ? (letter_to(letter.date, 'time')) : ('')}
                    {(typeof letter.photo != "undefined") ? (
                        <SRLWrapper options={SRLoptions}>
                        <img src={ require('../assets/images/'+letter.photo) }  alt={letter.imageAlt}/>
                        </SRLWrapper>
                    ) : ('')}
                </div>
            </div>
            <div className="Body">
                <div className="BodyParas">
                    {letter.paragraphs.map((object,i) =>
                        <p className="paragraphs" key={i}>{object}</p>
                    )}
                </div>
            </div>
            <div className="Signature">
                <div>
                    <div>{letter.from}</div>
                    <div><i>{letter.work}</i></div>
                </div>
                {letter.signature === true &&
                <div>
                    <img src={ require('../assets/'+letter.signatureImg) } className="imgWeb"  alt="Signaturen til {letter.from}"/>
                    {letter.signaturePrint === true &&
                        <img src={ require('../assets/'+letter.signaturePrintImg) } className="imgPrint" alt="Signaturen til {letter.from} ment for Print funksjonen"/>
                    }
                </div>
                }
            </div>
        </div>
    )
}

export default coverLetter