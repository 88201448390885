const json = {
    "basicInfo":{
        "name":"André Tørlen Lønvik",
        "subtitle":"Interaksjonsdesigner",
        "phone":"909 90 065",
        "email":"andre@lonvik.no",
        "location":"Gjøvik / Trondheim"
    },
    "online": {
        "has_online":true,
        "url":"cv.andre.agency",
        "text":"Link til CV"
    },

    "skills": [
        "Design av interaktive produkter", "Informasjonsarkitektur", "App design", "Brukersentrert design", "UX Web Design",
        "Adobe Suit", "HTML5", "CSS3", "Javascript", "Web usability", "Interface Design",
        "Python", "Flask", "SQLite3", "MySQL",
    ],

    "experiences": [
        {
            "name": "User Experience Intern",
            "location": "Ferskvann AS",
            "startDate": "Jan 2020",
            "endDate": "Mar 2020",
            "description": "Praksis arbeid for Ferskvann AS. Arbeidet gikk ut på å designe et wayfindings system i et bygg og design av grafikk for å bruke som dekor. Se vedlegg med portofølje eller klikk på Portefølje linken i sidepanelet."
        },{
            "name": "Styremedlem og PR&Foto Ansvarlig (Frivillig)",
            "location": "Studentenes Hus Gjøvik (Huset)",
            "startDate": "2019",
            "endDate": "Pågående",
            "description":"Styremedlem i Studentenes Hus Gjøvik. Huset er det sosiale samlingspunktet for alle studenter på Gjøvik og holder aktiviteter hver uke for alle som ønsker å delta.. PR & Foto-ansvarlig har ansvaret for all ekstern kommunikasjon som går ut fra HUSET, i hovedsak i form av plakater, Facebook arrangement, og sosiale medier ellers.",
            "list": [
                "Planlegge aktiviteter for studenter",
                "Sette opp timelister for frivillige",
                "Promotere og markedsføre Huset",
                "Være kontaktpunktet mellom studenter og ansatte på Huset.",
                "Produsere eller være delaktig i produksjonen av promoteringsmateriale."
            ]
        },{
            "name": "Fotograf og Ordensvakt (Frivillig)",
            "location": "Studentenes Hus Gjøvik (Huset)",
            "startDate": "2017",
            "endDate": "2019",
            "description":"Som fotograf sammarbeider du med opptil 5 fotografer på et event for å dokumentere eventet. Som vakt passer du på at alle har det bra, ingen blir overstadig beruset og hjelper til med å rydde opp etter store eventer."
        },{
            "name": "Fagassistent IMT1292 (Webkoding)",
            "location": "NTNU i Gjøvik",
            "startDate": "2018",
            "endDate": "2018",
            "description":"",
            "list":[
                "Være tilgjengelig for å hjelpe over 90 studenter med spørsmål de kan ha om faget.",
                "Planlegge studiedager og tilrettelegge for studenter til å lære.",
                "Assistere foreleser med leksjoner.",
                "Kunne besvare faglige spørsmål fra studenter."
            ]
        },{
            "name": "Logistikkassistent",
            "location": "Sjøforsvaret - Førstegangstjeneste",
            "startDate": "2016",
            "endDate": "2016",
            "description":"Som en logistikk assistent i sjøforsvaret, så skal man være selvstendig og hjelpe til med det som er nødvendig. Dette innebærer transport av utstyr over lengre strekninger, hente pakker på lager, vaske biler, sjekke og vedlikeholde utstyr slik at det alltid er preparert og kan brukes, assistere med flytting av tungt utstyr m.m. Arbeidet ble gjennomført i både små og store team."
        }
    ],

    "strengths": [
        {
            "text": "Team arbeid",
            "icon": "Team"
        },{
            "text": "Pålitelig og fleksibel",
            "icon": "VUI"
        },{
            "text": "Muntlig og skriftlig kommunikasjon",
            "icon": "Chat"
        }
    ],

    "education": [
        {
            "degree":"Master interaction design",
            "university":"NTNU",
            "startDate":"Aug. 2020",
            "endDate":"Jun. 2022",
            "description":"The Master program in Interaction Design teaches the students to put the users' needs and requirements first in digital interactive environments and user interfaces. The program provides the students with knowledge and awareness of human factors and conditions in interaction and interface design."
        },{
            "degree":"Bachelor i Interaksjonsdesign",
            "university":"NTNU",
            "startDate":"Aug. 2017",
            "endDate":"Jun. 2020",
            "description":"Studiet var rettet mot å designe applikasjoner og webløsninger som er brukervennlige, inkluderende, inspirerende, nyttige, og sosialt og etisk ansvarlige. Det jeg lærte var hvordan man skal designe interaksjon mellom mennesker og løsninger, dette innebærer tjenestedesign løsninger eller design som påvirker adferden og hjelpe brukerene ta gode valg. Jeg hadde fokus på app- & web-design, og utvikling av testbare produkter."
        }
    ],

    "certifications": [
        {
            "name": "Nivå 2 - Kvalifisert førstehjelp",
            "from": "Sjøforsvaret"
        },{
            "name": "Truckfører - T1-T4",
            "from": "Sjøforsvaret"
        }
    ],

    "frameworks": [
        "React","Django", "Drupal","Wordpress"
    ],

    "socialMedia": [
        {
            "name":"andre_lonvik",
            "alt":"Instagram",
            "icon":"ig",
            "link": "https://www.instagram.com/andre_lonvik/"
        },{
            "name":"andré-lønvik",
            "alt":"LinkedIn",
            "icon":"li",
            "link": "https://www.linkedin.com/in/andr%C3%A9-l%C3%B8nvik/"
        },{
            "name":"Burning-Panda",
            "alt":"GitHub",
            "icon":"git",
            "link":"https://github.com/Burning-Panda"
        },{
            "name":"Portefølje",
            "alt":"Min portefølje",
            "icon":"Adobe",
            "link":"https://alonvik.myportfolio.com/"
        }
    ],

    "projects": [
        {
            "name": "NTNUI redesign",
            "url":"https://alonvik.myportfolio.com/ntnui-homepage",
            "description": "Jeg har inngått et sammarbeid med NTNUI i å redesigne hjemmesiden deres. Dette er et arbeid som fremdeles er pågående, men tar ekstra tid ettersom det gjøres på fritiden.",
            "tools":["Figma"],
            "image":"NTNUI-Redesign.jpg",
            "imageAlt":"Samling av bilder for promotering av IAMK på Studentenes Hus Gjøvik"
        },{
            "name": "Navigasjon og dekorasjon i bygg",
            "url":"https://alonvik.myportfolio.com/navigasjon-og-dekorasjon-pa-mtnc",
            "description": "Oppgaven gikk ut på å designe et funksjonelt navigasjonssystem i et bygg, og design av grafikk for å bruke som dekor på vegger. Dette var en praksis oppgave på maksimum 180 timer. Jeg hadde ansvaret for alt av design, informasjonsinnsamling og utvikling. Dag-Inge fra Ferskvann AS designet de endelige ikonenene ut fra sketsjer og ideer.",
            "tools": ["Adobe XD", "Adobe Illustrator", "Adobe Photoshop"],
            "image":"internship.png",
            "imageAlt":"Alt"
        },{
            "name": "KoLab ― Kamerasystem for samarbeidslæring",
            "url": "https://github.com/Burning-Panda/Nurse",
            "description": "Oppgaven var å utvikle et intuitivt kamerasystem for at studenter ved IHG, som tillater studentene filme seg selv og andre mens de utfører “passeringstester” i sykepleieutdanningen og lar studieveiledere følge med på progressjonen til studentene.",
            "tools": ["HTML", "CSS", "Javascript", "Python", "Flask", "SQLite", "Adobe XD"],
            "image":"KoLab.png",
            "imageAlt":"Samling av bilder som viser arbeid på et bachelor prosjekt."
        },{
            "name": "Redesign av Logo og profil ikon",
            "description": "En egen oppgave hvor jeg tokk for meg å redesigne et profilbildet jeg selv bruker på f.eks. GitHub. Oppgaven var å simplifisere og gjøre det mer relevant til navnet.",
            "tools":["Illustrator"],
            "image":"redesign-logo.png",
            "imageAlt":"Samling av bilder som viser redesign prosessen av et profilbildet"
        },{
            "name": "Redesign av online CV",
            "description": "Min egen nettside holder ikke opp til standarden jeg sette for meg selv, så jeg har nå redesignet og er i gang med å programmere den.",
            "tools": ["Figma"],
            "url":"https://www.figma.com/file/ijS9VFBXmBWkF4qXLMSWGI/My-Portfolio?node-id=0%3A1",
            "image": "NewCV_Design.jpg"
        }, {
            "name": "CV built with React",
            "description": "Denne CVen er bygd med react for å gjøre det lett å produsere en oppdatert versjon.",
            "tools":["Adobe XD", "HTML", "CSS", "Javascript", "React"],
            "url":"https://github.com/Burning-Panda/React-Print-ready-CV"
        }
    ],


    "coverLetter": {
        "title":"Om meg.",
        "to":"",
        "date":"", //"date":"1914-12-20"
        "paragraphs": [
            "Jeg er en nyutdannet interaksjonsdesigner fra NTNU, hvor jeg lærte om hvordan mennesker og maskiner kommuniserer sammen. Det jeg valgte å fokusere på i studiet var å utarbeide produkter og tjenester som kan forenkle, forbedre og hjelpe til i hverdagen til personer. ",
            "Et eksempel på dette er bachelor prosjektet mitt, KoLab. Prosjektet var å utvikle et intuitivt kamerasystem for at studenter ved Instituttet for Helsefag i Gjøvik, som tillater studentene å filme seg selv og andre mens de utfører “passeringstester” i sykepleieutdanningen. ",
            "Prosjektet tok for seg hvordan studentene gjennomførte testene, hvordan lektorene vurderte disse testene, og sørget for at de som skal forvalte og kanskje videreutvikle sluttproduktet lett kunne gjøre dette. Min oppgave i dette prosjektet var utvikleren, hvor jeg tok utfordringen i å lære meg Python og SQLite. Dette brukte jeg til å programmere en MVP som nå skal brukes på NTNU i Gjøvik. ",
            "Som person er jeg rolig, retningsfokusert og tar ofte på meg en lederrolle, slik at jeg kan hjelpe andre til å komme i gang med arbeidet som skal gjøres. Jeg er engasjert i å prøve å få folk til å like arbeidsplassen sin, og det viktig for meg at man som et team skaper et godt arbeidsmiljø! ",
            "På fritiden går jeg ofte på turer ut for å ta bilder eller jeg jobber med private programmerings-prosjekter, noe jeg er veldig interessert i. Jeg er veldig aktiv i studentmiljøet i Gjøvik, hvor jeg sitter som leder av PR&Foto avdelingen. Som leder i PR&Foto avdelingen leder jeg en gruppe med 16+ grafikere og fotografer. Mine oppgaver inkluderer å planlegge timelister, setter opp oppgaver, promotere Huset via sosiale medier med mer. ",
            "Jeg er godt kjent med å arbeide hardt og er flink til å samarbeide med andre. Da jeg var i førstegangstjenesten i forsvaret, fikk jeg god holdning til å være punktlig, oppmerksom og ryddig. Dette er noe som jeg har tatt med meg videre ut i studiet og arbeidslivet."

        ],

        "from":"André T. Lønvik",
        "work":"Interaksjonsdesigner",
        "signature":true,
        "signatureImg":"Main.png",
        "signaturePrint":true,
        "signaturePrintImg":"Main2.png",
        "photo":"Andre_Torlen_Lonvik.jpg",
        "photoAlt":"Bilde av André Tørlen Lønvik."
    },
    "coverLetterLong": {
        "title":"Om meg.",
        "to":"",
        "date":"", //"date":"1914-12-20"
        "paragraphs": [
            'Jeg er en nyutdannet interaksjonsdesigner fra NTNU, hvor jeg lærte om hvordan mennesker og maskiner kommuniserer sammen. Det jeg valgte å fokusere på i studiet var å utarbeide produkter og tjenester som kan forenkle, forbedre og hjelpe til i hverdagen til personer. Dette er i form av app- og web-design, og utvikling av testbare produkter.',
            'Et eksempel på dette er bachelor prosjektet mitt, KoLab. Prosjektet var å utvikle et intuitivt kamerasystem for at studenter ved Instituttet for Helsefag i Gjøvik, som tillater studentene å filme seg selv og andre mens de utfører “passeringstester” i sykepleieutdanningen.',
            'Prosjektet tok for seg hvordan studentene gjennomførte testene, hvordan lektorene vurderte disse testene, og sørget for at de som skal forvalte og kanskje videreutvikle sluttproduktet lett kunne gjøre dette. Min oppgave i dette prosjektet var utvikleren, hvor jeg tok utfordringen i å lære meg Python og SQLite. Dette brukte jeg til å programmere en MVP som nå skal brukes på NTNU i Gjøvik, jeg bruker også litt av fritiden min på å hjelpe de med justeringer og testing angående dette prosjektet.',
            'Min oppgave i dette prosjektet var utvikleren, hvor jeg tok utfordringen i å lære meg Flask framework for Python og SQLite. Dette brukte jeg til å programmere en MVP som nå skal brukes på NTNU i Gjøvik. Les mer på portfolio linken under. Eller se direkte link i CV under "Portfolio".',
            'Et ordtak som aldri forlot meg var «Gjør feil ofte og raskt», som har vært min inspirasjon for mine prosjektet, men dette gjør ikke at jeg satser på å gjøre feil, derimot gjør at jeg forstår hvorfor man skal gjøre feil noen ganger.',
            'Som person er jeg rolig, retningsfokusert og tar ofte på meg en lederrolle, slik at jeg kan hjelpe andre til å komme i gang med arbeidet som skal gjøres. Jeg er engasjert i å prøve å få folk til å like arbeidsplassen sin, og det viktig for meg at man som et team skaper et godt arbeidsmiljø!',
            'På fritiden går jeg ofte på turer ut for å ta bilder eller jeg jobber med private programmeringsprosjekter, noe jeg er veldig interessert i. Jeg er veldig aktiv i studentmiljøet i Gjøvik, hvor jeg sitter som leder av PR&Foto avdelingen. Som leder i PR&Foto avdelingen leder jeg en gruppe med 16+ grafikere og fotografer. Mine oppgaver inkluderer å planlegge timelister, setter opp oppgaver, promotere Huset via sosiale medier med mer.',
            'Et eksempel på min interesse for å lære utvikling er CV-en jeg bygget med React, denne kan man se på et av domenene mine (cv.andre.agency). Et lite utvalg av mindre arbeid kan sees på denne siden: alonvik.myportfolio(https://alonvik.myportfolio.com/work). Er det ønskelig å se et utvalg av bilder jeg har tatt, kan det sees på denne kontoen Instagram: @andre_lonvik.',
            'Jeg er godt kjent med å arbeide hardt og er flink til å samarbeide med andre. Da jeg var i førstegangstjenesten i forsvaret, fikk jeg god holdning til å være punktlig, oppmerksom og ryddig. Dette er noe som jeg har tatt med meg videre ut i studiet og arbeidslivet.',
            'Jeg håper jeg virker som en interessant kandidat for denne jobben og gleder meg til å høre fra dere.'

        ],
        "from":"André T. Lønvik",
        "work":"Interaksjonsdesigner",
        "signature":true,
        "signatureImg":"Main.png",
        "photo":"Andre_Torlen_Lonvik.jpg",
        "photoAlt":"Bilde av André Tørlen Lønvik."
    }
}


export default json