import React from 'react';
import json from "./assets/json";
import SimpleReactLightbox from "simple-react-lightbox";
import {AlternateEmail, LocationOn, Phone} from "@material-ui/icons";
import skills from "./cards/skillsCard";
import experiences from "./cards/experiencesCard";
import publications from "./cards/publications";
import education from "./cards/educationCard";
import strengths from "./cards/strengthCard";
import certifications from "./cards/certificationsCard";
import sosMed from "./cards/sosialMediaCard";
import projects from "./cards/projectCard";
import coverLetter from "./cards/coverLetterCard";
import isonline from "./cards/isAvailableOnlineCard";

/*

const url = 'http://localhost/_/test/get_data.php?userData=1';
fetch(url)
    .then((resp) => resp.json())
    .then(function(data) {
      let json = data.results;
      return(
  )
  })
})
.catch(function(error) {
    console.log(error);
});

let json = fetch(url).then((resp) => resp.json()).then(function(data) {return(data)}).catch(function(error) {console.log(error)};
* */

const pageSeperatorIsNeeded = false;

function App() {
  function pageSeperatorView() {
    if (pageSeperatorIsNeeded === true) {
      return (<div className="pageSeperator"/>)
    }
  }
    return (
    <div className="App">

      <SimpleReactLightbox>

      {pageSeperatorView()}
        <header>
          <h1>{json.basicInfo.name}</h1>
          <h2>{json.basicInfo.subtitle}</h2>
          <div className="basicInfo">
            <div className="phone">
              <p><Phone /></p>
              {json.basicInfo.phone}
            </div>
            <div className="email">
              <p><AlternateEmail /></p>
              {json.basicInfo.email}
            </div>
            <div className="location">
              <p><LocationOn /></p>
              {json.basicInfo.location}
            </div>
          </div>
        </header>

        <main className="sizer">
          {(typeof json.education != "undefined") ? (education(json.education)) : ('')}
          {(typeof json.experiences != "undefined") ? (experiences(json.experiences)) : ('')}
          {(typeof json.publications != "undefined") ? (publications(json.publications)) : ('')}

        </main>


        <aside>
          {(typeof json.socialMedia != "undefined") ? (sosMed(json.socialMedia)) : ('')}
          {(typeof json.strengths != "undefined") ? (strengths(json.strengths)) : ('')}
          {(typeof json.skills != "undefined") ? (skills(json.skills)) : ('')}

          {(typeof json.certifications != "undefined") ? (certifications(json.certifications)) : ('')}

          {(typeof json.online != "undefined") ? (isonline(json.online)) : ('')}
        </aside>

        <div className="headLarge">
          <div className="projectsHeader">
            <h3>Portfolio</h3>
            <p>Mine Prosjekter</p>
          </div>
        </div>
        <div className="otherProjects">
          {(typeof json.projects != "undefined") ? (
              projects(json.projects)
          ) : ('')}
        </div><div className="mainCoverLetter">
        {(typeof json.coverLetter != "undefined") ? (
            coverLetter(json.coverLetter)
        ) : ('')}
      </div>

        <footer>
          <span>Created by <a href="https://github.com/Burning-Panda" target="_blank" rel="noopener noreferrer">André T. Lønvik</a>.</span>
        </footer>

      </SimpleReactLightbox>
    </div>
  );
}

export default App;
